$blue-grey: #80859f;
$cream: #fff8bd;
$dark-grey: #23242a;
$electric-blue: #004ffe;
$ice: #d6ffdb;
$off-white: #fffce3;
$pale-turquoise: #a6ffdb;
$purply-pink: #f16fff;
$very-light-pink: #eee;
$white: #fff;
