html,
body {
  height: 100%;
}

.with-line-break {
  white-space: pre-wrap; 
}

.gap-0 {
  gap: 0rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 3rem;
}

