@import "colors";

// Override colors
$primary: $electric-blue;
$secondary: $purply-pink;
$light: $very-light-pink;

// Add missing utilities
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
}

@import "~bootstrap/scss/bootstrap.scss";
